import React from "react";
import styled from "styled-components";
import Layout from "../layout";
import { withApiData, useApiData } from "react-api-data";
import { useQueryParam, StringParam } from "use-query-params";
import CheckoutCart from "../components/sponsorartikelen/CheckoutCart";
import { ButtonConfirm } from "../components/sponsorartikelen/CheckoutForm";

const enhance = withApiData({
  reorder: "mollieOrder",
});
const OrderPage = (props) => {
  const [id, setId] = useQueryParam("id", StringParam);
  const order = useApiData("getOrder", { id });
  return (
    <Layout>
      <OrderContainer>
        {order && order.request.networkStatus === "success" && (
          <Content>
            <Message>
              {order.data.status === "paid"
                ? `${order.data.billingAddress.givenName}, bedankt voor je bestelling!`
                : "Je bestelling is geannuleerd"}
            </Message>
            {!(order.data.status === "paid") && (
              <ButtonConfirm
                onClick={() =>
                  props.reorder.perform(
                    {},
                    {
                      firstName: order.data.billingAddress.givenName,
                      lastName: order.data.billingAddress.familyName,
                      amount: order.data.amount.value,
                      email: order.data.billingAddress.email,
                      postcode: order.data.billingAddress.postalCode,
                      streetAndNumber:
                        order.data.billingAddress.streetAndNumber,
                      city: order.data.billingAddress.city,
                      lines: order.data.lines,
                    }
                  )
                }
              >
                Probeer opnieuw
              </ButtonConfirm>
            )}
            <CheckoutCart
              isThankYou
              products={order.data.lines}
              total={order.data.amount.value}
            />
          </Content>
        )}
      </OrderContainer>
    </Layout>
  );
};

const OrderContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url("http://res.cloudinary.com/djhc9jhiq/image/upload/v1530633075/_MG_1546_u5jxkv.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Message = styled.h1`
  color: white;
`;

export default enhance(OrderPage);
