import React, { Component } from "react";
import styled from "styled-components";
import cross from "../images/cancel.svg";
import checked from "../images/checked.svg";

class TextInput extends Component {
  state = {
    isEmpty: true,
    value: null,
    valid: false,
    errorMessage: "Input is invalid",
    errorVisible: false,
  };

  validate = (value) => {
    if (this.props.validation && this.props.validation(value)) {
      this.setState({ valid: true });
    }
  };

  render() {
    return (
      <InputContainer>
        <Input
          placeholder={this.props.text}
          className="input-form"
          ref="input"
          type={this.props.type}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
          step=".10"
          min={!!this.props.min ? this.props.min : 0}
          value={this.props.value}
        />
        {this.props.validation ? (
          <InputIcon
            src={
              this.props.validation(this.props.value) && !this.props.value <= 0
                ? checked
                : cross
            }
          />
        ) : (
          <InputIcon src={!this.props.value <= 0 ? checked : cross} />
        )}
      </InputContainer>
    );
  }
}

const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-items: center;
  @media only screen and (min-width: 275px) and (max-width: 430px) {
    width: 270px;
  }
`;

const InputIcon = styled.img`
  position: relative;
  right: 30px;
  width: 10px;
  height: 10px;
`;

const Input = styled.input`
  position: relative;
  width: 100%;
  height: 40px;
  padding-left: 5px;
  border-radius: 4px;
  border-style: none;
  background-color: #ffffff;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;

  @media only screen and (min-width: 275px) and (max-width: 430px) {
    width: 270px;
  }
`;

export default TextInput;
