import * as types from "../constants/Types";

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

// export const getAllProducts = () => dispatch => {
//   shop.getProducts(products => {
//     dispatch(receiveProducts(products))
//   })
// };

const fissa = (productId, quantity) => ({
  type: types.REMOVE_FROM_CART,
  productId,
  quantity,
});

const addToCartUnsafe = (productId, quantity) => ({
  type: types.ADD_TO_CART,
  productId,
  quantity,
});

export const removeProduct = (productId, quantity) => (dispatch, getState) => {
  if (getState().products.byId[productId]) {
    dispatch(fissa(productId, quantity));
  }
};

export const addToCart = (productId, quantity) => (dispatch, getState) => {
  if (getState().products.byId[productId]) {
    dispatch(addToCartUnsafe(productId, quantity));
  }
};
