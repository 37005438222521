import React from "react";
import { connect } from "react-redux";
import { getTotal, getCartProducts } from "../../reducers";
import CheckoutCart from "./CheckoutCart";

const CheckoutCartContainer = ({ products, total }) => {
  return <CheckoutCart products={products} isCheckout total={total} />;
};

const mapStateToProps = (state) => ({
  products: getCartProducts(state),
  total: getTotal(state),
});

export default connect(mapStateToProps)(CheckoutCartContainer);
