import { connect } from "react-redux";
import React, { Component } from "react";
import TextInput from "../../components/TextInput";
import { getTotal, getCartProducts } from "../../reducers";
import CheckoutCart from "./CheckoutCartContainer";
import styled, { css } from "styled-components";
import { withApiData } from "react-api-data";
import { compose } from "redux";
import { ClipLoader } from "react-spinners";

export const postalCodeValidation = (postalCode) => {
  const regex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
  return regex.test(postalCode);
};

export const emailValidation = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
const override = css`
  margin-left: 30px;
`;

class CheckoutForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    amount: this.props.amount,
    products: this.props.products,
    email: "",
    postcode: "",
    houseNumber: "",
    street: "",
    extra: "",
    city: "",
  };

  addressLookup() {
    if (
      postalCodeValidation(this.state.postcode) &&
      this.state.houseNumber.length > 0
    ) {
      this.props.addressLookup
        .perform({
          postcode: this.state.postcode,
          number: this.state.houseNumber,
        })
        .then((result) => {
          console.log(result);
          this.setNewAddress(result);
        });
    }
  }

  setNewAddress(result) {
    if (result.request.networkStatus === "success" && result.data) {
      this.setState({
        street: result.data.street,
        city: result.data.city,
      });
    }
  }

  submitForm(event) {
    event.preventDefault();
    //contributor:this.refs.contributor.getValue(),
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const amount = this.state.amount;
    const city = this.state.city;
    const street = this.state.street;
    const products = this.state.products;
    const email = this.state.email;
    const postcode = this.state.postcode;
    const houseNumber = this.state.houseNumber;
    const streetAdd = this.state.extra.length > 0 ? this.state.extra : " ";
    this.props.postProducts.perform(
      {},
      {
        firstName,
        lastName,
        amount,
        email,
        postcode,
        houseNumber,
        city,
        street,
        products,
        streetAdd,
      }
    );
  }

  setValue(field, event) {
    var object = {};
    object[field] = event.target.value;
    this.setState(object);
  }

  render() {
    const allStatesSet =
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.city.length > 0 &&
      this.state.street.length > 0 &&
      postalCodeValidation(this.state.postcode) &&
      emailValidation(this.state.email) &&
      this.state.houseNumber.length > 0;
    return (
      <CheckoutFormContainer onSubmit={this.submitForm} autocomplete={"on"}>
        <CheckoutFormHeader>Bestelling afronden </CheckoutFormHeader>
        <CheckoutCart />
        <CheckoutSubHeader>Uwgegevens </CheckoutSubHeader>
        <InnerForm>
          <TextInput
            ref={"firstName"}
            value={this.state.firstName}
            text="voornaam"
            uniqueName="firstName"
            required={true}
            isValid={true}
            onChange={this.setValue.bind(this, "firstName")}
            errorMessage="Name is invalid"
            emptyMessage="Name is required"
          />

          <TextInput
            ref={"lastName"}
            value={this.state.lastName}
            text="achternaam"
            uniqueName="firstName"
            required={true}
            isValid={true}
            onChange={this.setValue.bind(this, "lastName")}
            errorMessage="Name is invalid"
            emptyMessage="Name is required"
          />
          <TextInput
            type="email"
            ref="email"
            value={this.state.email}
            uniqueName="e-mail"
            text="e-mail"
            required={true}
            minCharacters={6}
            validation={emailValidation}
            onChange={this.setValue.bind(this, "email")}
            errorMessage="Email is invalid"
            emptyMessage="Email is required"
          />

          <TextInput
            ref="postocde"
            value={this.state.postcode}
            text="postcode"
            uniqueName="postcode"
            required={true}
            minCharacters={3}
            validation={postalCodeValidation}
            // onBlur={() => this.addressLookup()}
            onChange={this.setValue.bind(this, "postcode")}
            errorMessage="Name is invalid"
            emptyMessage="Name is required"
          />

          <TextInput
            ref="street"
            value={this.state.street}
            text="straat"
            uniqueName="street"
            required={true}
            minCharacters={1}
            validate={this.commonValidate}
            onChange={this.setValue.bind(this, "street")}
            errorMessage="number is invalid"
            emptyMessage="number is required"
          />

          <TextInput
            ref="houseNumber"
            value={this.state.houseNumber}
            text="huisnr."
            type={"number"}
            uniqueName="huisnummer"
            required={true}
            minCharacters={1}
            validate={this.commonValidate}
            // onBlur={() => this.addressLookup()}
            onChange={this.setValue.bind(this, "houseNumber")}
            errorMessage="number is invalid"
            emptyMessage="number is required"
          />
          <TextInput
            ref="extra"
            value={this.state.extra}
            text="toev."
            uniqueName="toevoeging"
            required={true}
            minCharacters={1}
            validate={this.commonValidate}
            // onBlur={() => this.addressLookup()}
            onChange={this.setValue.bind(this, "extra")}
          />
          <TextInput
            ref="city"
            value={this.state.city}
            text="plaats"
            uniqueName="city"
            required={true}
            minCharacters={1}
            validate={this.commonValidate}
            onChange={this.setValue.bind(this, "city")}
            errorMessage="number is invalid"
            emptyMessage="number is required"
          />
        </InnerForm>
        <ButtonConfirm
          disabled={!allStatesSet}
          onClick={allStatesSet && this.submitForm.bind(this)}
          label="Betaal"
          primary={true}
        >
          Betalen
          <ClipLoader
            sizeUnit={"px"}
            size={12}
            css={override}
            style={{ marginLeft: "15px" }}
            color={"#fffff"}
            loading={
              this.props.postProducts.request.networkStatus === "loading"
            }
          />
        </ButtonConfirm>
      </CheckoutFormContainer>
    );
  }
}

export const CheckoutFormContainer = styled.form`
  display: flex;
  padding: 37px;
  width: 600px;
  background-color: #fef4ea;
  flex-direction: column;
  @media only screen and (min-width: 275px) and (max-width: 430px) {
    width: 100%;
    min-height: 100vh;
    margin: 0px;
  }
`;

export const InnerForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckoutFormHeader = styled.p`
  font-family: "Nunito Sans";
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: left;
  color: #333333;
`;

const CheckoutSubHeader = styled.p`
  margin-top: 37px;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;
`;

export const ButtonConfirm = styled.button`
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  width: 300px;
  height: 40px;
  margin-top: 37px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  cursor: pointer;
  color: white;
  letter-spacing: 0.2px;
  text-align: center;
`;
const mapStateToProps = (state) => ({
  products: getCartProducts(state),
  amount: getTotal(state),
});

export default compose(
  withApiData({
    postProducts: "mollieOrder",
  }),
  connect(mapStateToProps)
)(CheckoutForm);
